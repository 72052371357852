export default {
  props: {
  },
  computed: {
    gridColumns() {
      let cols = null;
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: 'LBL0001001',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: 'LBL0001004',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'deviationName',
            field: 'deviationName',
            // 이탈
            label: 'LBL0001005',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: 'LBL0001006',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:200px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                type: 'custom',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:130px',
            type: 'custom',
            sortable: false
          },
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        cols = [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'jobName',
            field: 'jobName',
            // 작업
            label: '작업순서',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardName',
            field: 'riskHazardName',
            // 분류-유해위험요인
            label: 'LBL0001020',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: '상황/원인',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:150px',
            sortable: false
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:35px',
                sortable: false
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                type: 'custom',
                style: 'width:50px',
                sortable: false,
              },
            ]
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ]
      } 
      return cols;
    },
    gridMerge() {
      let merge = [];
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'processDiagram' }, // 도면
          { index: 2, colName: 'processDiagramNode' }, // 노드
          { index: 0, colName: 'deviationName' },  // 이탈
        ]
      } else if (this.searchParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        merge = [
          { index: 0, colName: 'processCd' }, // 공정
          { index: 1, colName: 'jobGubun' }, // 작업
          { index: 2, colName: 'riskHazardGubun' }, // 유해위험요인
        ]
      }
      return merge;
    },
    imprProps() {
      let props = {};
      // 공정 - 구분 - 유해위험요인 - 현상태 - 위험도(전) - 조치 - 위험도(후) - 개선
      if (this.searchParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        props = {
          tableKey: 'ramHazopAssessScenarioId', ibmTaskTypeCd: 'ITT0000001', ibmTaskUnderTypeCd: 'ITTU000001',
          requestContentsCols: ['deviationName', 'riskOccurrenceCause']
        }
      } else if (this.searchParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        props = {
          tableKey: 'ramKrasAssessScenarioId', ibmTaskTypeCd: 'ITT0000020', ibmTaskUnderTypeCd: 'ITTU000025',
          requestContentsCols: ['jobName', 'ram4mRiskHazardClassName', 'riskOccurrenceCause']
        }
      } 
      return props;
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
